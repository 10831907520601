<template>
  <div class="mobileInternet">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>移动互联网解决方案</span>
          <span>移动互联网<br />解决方案</span>
          <p>
            百悟科技为移动APP应用提供基础通信及网络支撑，为用户实现移动通信。移动安全、移动加速等应用服务，满足业务发展需求，助力构建移动互联生态。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/solution/mobileInternet/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <section class="part2">
      <span class="part-title">行业分类</span>
      <div class="part2_main mobileInternetP2-pc">
        <swiper :options="swiperOption">
          <swiper-slide
            class="swiper-item"
            v-for="(item, i) in p2Datas"
            :key="i"
          >
            <div
              class="part2-item"
              v-for="(item2, i2) in item"
              :key="i2"
              @click="p2mClick($event, item2)"
            >
              <div>
                <img :src="item2.img" alt="" />
              </div>
              <span v-text="item2.span"></span>
              <p v-text="item2.p"></p>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination swiper_bg_blue" slot="pagination"></div>
      </div>
      <div class="part2_main mobileInternetP2-mobile">
        <swiper :options="swiperOptionMobile">
          <swiper-slide
            class="swiper-item"
            v-for="(item, i) in p2Data"
            :key="i"
          >
            <div class="part2-item" @click="p2mClick($event, item)">
              <div>
                <img :src="item.img" alt="" />
              </div>
              <span v-text="item.span"></span>
              <p v-text="item.p"></p>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination swiper_bg_blue" slot="pagination"></div>
      </div>
    </section>
    <PartCore ref="Part3Core" :pCoreData="p3Data"></PartCore>
    <PartAdvantage
      :pAdvantageData="p4Data"
      :pAdvantageDatas="p4Datas"
    ></PartAdvantage>
    <section class="part5">
      <span class="part-title">解决方案结构</span>
      <div class="p5_main reveal-bottom2">
        <img
          src="../../assets/img/solution/mobileInternet/part5-icon.png"
          alt=""
        />
        <div class="p5_right">
          <div class="p5_rLeft">
            <div
              class="p5_rLItem"
              v-for="(item, i) in p5Data"
              :key="i"
              @mousemove="p5Click(i)"
            >
              <span v-text="item.span"></span>
            </div>
          </div>
          <div class="p5_rRight">
            <div
              class="p5_rRItem"
              v-for="(item, i) in p5Data"
              :key="i"
              @mousemove="p5Click(i)"
            >
              <p v-text="item.p"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <PartImgSwiper
      ref="part6Img"
      :pImgData="p6Data"
      :pImgDatas="p6Datas"
    ></PartImgSwiper>
  </div>
</template>

<script>
import PartImgSwiper from '@/components/solution/PartImgSwiper.vue';
import PartCore from '@/components/solution/PartCore.vue';
import PartAdvantage from '@/components/solution/PartAdvantage.vue';
export default {
  data() {
    return {
      // p2
      p2Data: [
        {
          img: require('../../assets/img/solution/mobileInternet/part2-icon1.png'),
          imgOver: require('../../assets/img/solution/mobileInternet/part2-icon1-over.png'),
          span: '直播',
          p: '直播行业已进入一个成熟的发展阶段，对于通信、安全、网络等需求日趋增多。基于专业的企业通信服务经验，百悟为直播行业客户提供稳定流畅、低延时、高并发的通信解决方案，构建企业自有直播生态，提升安全性和开放性。',
        },
        {
          img: require('../../assets/img/solution/mobileInternet/part2-icon2.png'),
          imgOver: require('../../assets/img/solution/mobileInternet/part2-icon2-over.png'),
          span: '视频',
          p: '视频网站、短视频APP的发展进入了一个新的生态趋势，行业升级引发了更多的基础通信及相关需求。百悟为视频行业客户提供端到端的解决方案，个性化定制方案保障用户访问与数据安全，满足客户基于通信的信息、安全、加速等需求，增强用户体验，助力行业互联网',
        },
        {
          img: require('../../assets/img/solution/mobileInternet/part2-icon3.png'),
          imgOver: require('../../assets/img/solution/mobileInternet/part2-icon3-over.png'),
          span: '游戏',
          p: '2017中国游戏行业整体营收近2200亿元，其中移动游戏占据半壁江山的市场。作为致力于为企业客户提供企业通信通信支撑的企业，百悟在游戏行业的生态产业链中，以移动通信、网络支撑、大数据等服务为行业客户提供最基础的动力支撑，助力业务快速发展。',
        },
        {
          img: require('../../assets/img/solution/mobileInternet/part2-icon4.png'),
          imgOver: require('../../assets/img/solution/mobileInternet/part2-icon4-over.png'),
          span: '社交',
          p: '随着移动互联网对各行业的深度渗透，社交网络得到了极大的发展，并不断呈现移动化、商业化的趋势，在构建强大的社交关系链中实现对信息广泛、快速的转播。百悟为社交领域客户提供基于企业通信的完善解决方案，满足社交行业客户的业务发展需求，提升竞争力。',
        },
        {
          img: require('../../assets/img/solution/mobileInternet/part2-icon5.png'),
          imgOver: require('../../assets/img/solution/mobileInternet/part2-icon5-over.png'),
          span: '移动电商',
          p: '移动电商的发展呈现出迅猛的势头，以大数据、云端服务等的技术支撑，不断实现随时随地、碎片化的发展特色，百悟科技以企业通信的服务，支撑移动电商一体化的商品、交易、支付的开放体系，保障移动端购物体验，构建移动电商生态链。',
        },
        {
          img: require('../../assets/img/solution/mobileInternet/part2-icon6.png'),
          imgOver: require('../../assets/img/solution/mobileInternet/part2-icon6-over.png'),
          span: '应用APP',
          p: '随着移动互联网的发展，新闻、旅游、招聘、医疗等各行业都在往移动APP应用化，也衍生出更多基于信息、安全、网络等的需求，百悟科技企业通信，为各种移动APP提供定制化的企业通信解决方案，助力提升APP的用户体验。',
        },
      ],
      p2Datas: [],
      swiperOption: {
        pagination: {
          el: '.mobileInternetP2-pc .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      swiperOptionMobile: {
        pagination: {
          el: '.mobileInternetP2-mobile .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      // p3
      p3Data: [
        {
          img: require('../../assets/img/solution/mobileInternet/part3-icon1.png'),
          img2: require('../../assets/img/solution/mobileInternet/mobile/part3-icon1.png'),
          imgOver: require('../../assets/img/solution/mobileInternet/part3-icon1-over.png'),
          span: '实施消息推送',
          p: '实时信息推送实现移动互联网企业与用户的高效信息沟通',
        },
        {
          img: require('../../assets/img/solution/mobileInternet/part3-icon2.png'),
          img2: require('../../assets/img/solution/mobileInternet/mobile/part3-icon2.png'),
          imgOver: require('../../assets/img/solution/mobileInternet/part3-icon2-over.png'),
          span: '静态/动态加速',
          p: '静态页面、动态视频加速有效应对高并发，保障良好的用户体验',
        },
        {
          img: require('../../assets/img/solution/mobileInternet/part3-icon3.png'),
          img2: require('../../assets/img/solution/mobileInternet/mobile/part3-icon3.png'),
          imgOver: require('../../assets/img/solution/mobileInternet/part3-icon3-over.png'),
          span: '业务快速上云',
          p: '移动互联网业务快速上云缩短上线周期，灵活应对业务发展',
        },
        {
          img: require('../../assets/img/solution/mobileInternet/part3-icon4.png'),
          img2: require('../../assets/img/solution/mobileInternet/mobile/part3-icon4.png'),
          imgOver: require('../../assets/img/solution/mobileInternet/part3-icon4-over.png'),
          span: '精准广告推送',
          p: '面向用户进行精准广告推送提升移动互联网业务量',
        },
        {
          img: require('../../assets/img/solution/mobileInternet/part3-icon5.png'),
          img2: require('../../assets/img/solution/mobileInternet/mobile/part3-icon5.png'),
          imgOver: require('../../assets/img/solution/mobileInternet/part3-icon5-over.png'),
          span: '企业客户服务热线',
          p: '面向用户进行精准广告推送提升移动互联网业务量',
        },
        {
          img: require('../../assets/img/solution/mobileInternet/part3-icon6.png'),
          img2: require('../../assets/img/solution/mobileInternet/mobile/part3-icon6.png'),
          imgOver: require('../../assets/img/solution/mobileInternet/part3-icon6-over.png'),
          span: '海量信息存储',
          p: '面向用户进行精准广告推送提升移动互联网业务量',
        },
      ],
      // p4
      p4Data: [
        {
          span: '全生命周期管理',
          p: '百悟将为移动互联网客户提供从信息咨询、产品提供、技术接入到售后服务于一体的全生命周期管理服务，专业的服务体系助力行业客户更快速的业务发展。',
        },
        {
          span: '企业一站式服务',
          p: '百悟将为移动互联网客户提供从实时通信、基础网络、高新技术、智慧创新到智能平台的一站式企业服务，完善的企业通信产品实现行业客户更生态的信息支撑。',
        },
        {
          span: '高效开发快速上云',
          p: '百悟完善的研发体系与平台接口，将为行业客户实现平台高效开发、业务快速上云，助力业务轻松开启，为移动互联网客户的业务开展提供实时保障与动力支撑。',
        },
        {
          span: '平台中立安全可靠',
          p: '百悟全球企业通信服务平台，基于企业自主研发，且不触碰数据源，是安全可靠的中立平台，为移动互联网行业客户的数据、信息安全性保驾护航。',
        },
      ],
      p4Datas: [],
      // p5
      p5Data: [
        {
          span: '移动通信',
          p: '为移动互联网客户提供国内/国际语音、短信增值业务服务，协助企业完成业务申请、平台搭建、运维支撑等工作，实现统一的移动商务与企业语音云通讯服务，提供行业移动通信的基础支撑。',
        },
        {
          span: '移动安全',
          p: '为移动互联网客户实现快速上云的解决方案，面向行业用户提供包括云主机、对象存储、负载均衡、数据库等一体的全面云计算解决方案与计算服务，打造移动安全的SaaS生态链。',
        },
        {
          span: '移动加速',
          p: '为移动互联网客户提供移动加速服务，从运维、系统、监控、上架、攻击防护、数据安全等方面为行业用户打造稳定高效的网络支撑与加速应用，提供最可靠、低成本、高度灵活的加速服务。',
        },
      ],
      // p6
      p6Data: [
        {
          img: require('../../assets/img/solution/mobileInternet/part6-icon1.png'),
          imgOver: require('../../assets/img/solution/mobileInternet/part6-icon1-over.png'),
        },
        {
          img: require('../../assets/img/solution/mobileInternet/part6-icon2.png'),
          imgOver: require('../../assets/img/solution/mobileInternet/part6-icon2-over.png'),
        },
        {
          img: require('../../assets/img/solution/mobileInternet/part6-icon3.png'),
          imgOver: require('../../assets/img/solution/mobileInternet/part6-icon3-over.png'),
        },
      ],
      p6Datas: [],
      swiperOption3: {
        pagination: {
          el: '.p6_m_mobile .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
    };
  },
  components: {
    PartImgSwiper,
    PartCore,
    PartAdvantage,
  },
  mounted() {
    // 数据初始化
    this.p2Datas = [
      JSON.parse(JSON.stringify(this.p2Data.slice(0, 3))),
      JSON.parse(JSON.stringify(this.p2Data.slice(-3))),
    ];
    this.p4Datas = [
      JSON.parse(JSON.stringify(this.p4Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p4Data.slice(-2))),
    ];
    this.p6Datas = [
      JSON.parse(JSON.stringify(this.p6Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p6Data.slice(-1))),
    ];
    this.start();
  },
  methods: {
    // p2
    p2mClick(e, item) {
      $('.part2-item').removeClass('active');
      $('.mobileInternetP2-pc .part2-item').each((index, item2) => {
        this.p2Data.find((item3) => {
          if (item3.p == $(item2).children('p').text()) {
            $(item2).find('img').attr('src', item3.img);
          }
        });
      });
      $(e.currentTarget).addClass('active');
      $(e.currentTarget).find('img').attr('src', item.imgOver);
    },
    // p5
    p5Click(i) {
      $('.p5_rLItem').removeClass('active');
      $('.p5_rRItem').removeClass('active');
      $('.p5_rLItem').eq(i).addClass('active');
      $('.p5_rRItem').eq(i).addClass('active');
    },
    // p6
    p6Click(i, img) {
      $('.p6_m_pc .p6_item').each((index, item2) => {
        $(item2).find('img').attr('src', this.p6Data[index].img);
      });
      $('.p6_m_pc .p6_item').removeClass('active');
      $('.p6_m_pc .p6_item').eq(i).addClass('active');
      $('.p6_m_pc .p6_item').eq(i).find('img').attr('src', img);
    },
    p6ClickMobile(i, i2, img) {
      $('.p6_m_mobile .p6_item').each((index, item2) => {
        $(item2).find('img').attr('src', this.p6Data[index].img);
      });
      $('.p6_m_mobile .p6_item').removeClass('active');
      $('.p6_m_mobile .swiper-item')
        .eq(i)
        .find('.p6_item')
        .eq(i2)
        .addClass('active');
      $('.p6_m_mobile .swiper-item')
        .eq(i)
        .find('.p6_item')
        .eq(i2)
        .find('img')
        .attr('src', img);
    },
    // 初始化
    start() {
      this.$refs.Part3Core.pCoreMove(0, this.p3Data[0].imgOver);
      this.$refs.Part3Core.pCoreClick(0, this.p3Data[0].img2, this.p3Data[0]);
      this.p5Click(0);
      this.$refs.part6Img.pImgClick(0, this.p6Data[0].imgOver);
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 723px;
  position: relative;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > img {
      position: absolute;
      width: 998px;
      height: 700px;
      z-index: -1;
      top: 23px;
      right: 0px;
    }
  }
  .part1-title {
    width: 538px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    span:nth-of-type(2) {
      display: none;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 67px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part2 {
  width: 100%;
  background: url('../../assets/img/solution/mobileInternet/part2-bg.png')
    no-repeat;
  background-size: 100% 100%;
  padding-top: 72px;
  padding-bottom: 135px;
  .part-title {
    color: #ffffff;
    margin-bottom: 50px;
  }
  .part2_main {
    width: 1126px;
    margin: 0 auto;
    .swiper-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px 10px;
    }
    .part2-item {
      box-sizing: border-box;
      width: 344px;
      height: 465px;
      background: #ffffff;
      box-shadow: 0px 30px 21px 0px rgba(196, 197, 199, 0.1);
      border-radius: 10px;
      padding-top: 36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      > div {
        height: 99px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      span {
        font-size: 22px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #333333;
        margin-bottom: 19px;
      }
      p {
        display: flex;
        width: 272px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 27px;
      }
    }
    .part2-item.active {
      background: linear-gradient(0deg, #0c63bb 0%, #6392cd 100%);
      border: 2px solid #ffffff;
      span,
      p {
        color: #ffffff;
      }
    }
  }
  .mobileInternetP2-mobile {
    display: none;
  }
}
.part5 {
  width: 100%;
  padding-top: 79px;
  padding-bottom: 127px;
  background: url('../../assets/img/solution/mobileInternet/part5-bg.png')
    no-repeat;
  background-size: 100% 100%;
  .part-title {
    color: #fefefe;
    margin-bottom: 62px;
  }
  .p5_main {
    width: 1111px;
    margin: 0 auto;
    height: 646px;
    background: rgba(241, 241, 241, 0.92);
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .p5_right {
      box-sizing: border-box;
      width: 513px;
      height: 579px;
      border: 2px solid #e4e6e9;
      border-radius: 7px;
      background: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .p5_rLeft {
        box-sizing: border-box;
        width: 152px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 16px;
        border-right: 1px solid #e4e6e9;
        .p5_rLItem {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          span {
            font-size: 24px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #333333;
          }
        }
        .p5_rLItem.active {
          span {
            color: #005cb9;
            position: relative;
          }
          span::after {
            content: '';
            display: block;
            width: 4px;
            height: 38px;
            background: #005cb9;
            position: absolute;
            top: -3px;
            left: -13px;
          }
        }
      }
      .p5_rRight {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .p5_rRItem {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          p {
            display: block;
            width: 300px;
            text-align: justify;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 27px;
          }
        }
        .p5_rRItem.active {
          p {
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  /* 第一部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        content: url('../../assets/img/solution/mobileInternet/mobile/part1-icon.png');
        width: 5.12rem;
        height: 4.08rem;
        top: 0.02rem;
        right: 0rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
        margin-top: -0.62rem;
        line-height: 0.7rem;
      }
      span:nth-of-type(1) {
        display: none;
      }
      span:nth-of-type(2) {
        display: block;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part2 {
    background: url('../../assets/img/solution/mobileInternet/mobile/part2-bg.png')
      no-repeat;
    background-size: 100% 100%;
    padding-top: 0.51rem;
    padding-bottom: 1.59rem;
    .part-title {
      margin-bottom: 0.45rem;
    }
    .part2_main {
      width: 5rem;
      .swiper-item {
        padding: 0px 0px;
      }
      .part2-item {
        width: 3.44rem;
        margin: 0 auto;
        height: 4.65rem;
        background: #ffffff;
        box-shadow: 0px 0.3rem 0.21rem 0px rgba(196, 197, 199, 0.1);
        border-radius: 0.1rem;
        padding-top: 0.36rem;
        > div {
          height: 0.99rem;
          margin-bottom: 0.12rem;
          img {
            max-height: 100%;
          }
        }
        span {
          font-size: 0.18rem;
          margin-bottom: 0.19rem;
        }
        p {
          width: 2.72rem;
          font-size: 0.16rem;
          line-height: 0.27rem;
        }
      }
      .part2-item.active {
        border: 0.02rem solid #ffffff;
      }
    }
    .mobileInternetP2-pc {
      display: none;
    }
    .mobileInternetP2-mobile {
      display: block;
    }
  }
  .part5 {
    padding-top: 0.58rem;
    padding-bottom: 1.1rem;
    background: #005cb9;
    .part-title {
      margin-bottom: 0.69rem;
    }
    .p5_main {
      box-sizing: border-box;
      width: 7.06rem;
      height: 12.12rem;
      border-radius: 0.07rem;
      padding: 0.32rem 0px;
      flex-direction: column;
      > img {
        width: 6.47rem;
      }
      .p5_right {
        width: 6.46rem;
        height: 4.8rem;
        border: 0.02rem solid #e4e6e9;
        border-radius: 0.07rem;
        .p5_rLeft {
          width: 1.52rem;
          padding-bottom: 0.16rem;
          .p5_rLItem {
            flex: 1;
            span {
              font-size: 0.24rem;
            }
          }
          .p5_rLItem.active {
            span::after {
              width: 0.04rem;
              height: 0.38rem;
              top: -0.03rem;
              left: -0.13rem;
            }
          }
        }
        .p5_rRight {
          .p5_rRItem {
            p {
              width: 4.12rem;
              font-size: 0.16rem;
              line-height: 0.27rem;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.mobileInternet {
  .mobileInternetP2-pc {
    .swiper_bg_blue {
      width: 1126px;
      margin-top: 35px;
    }
  }
}
@media screen and (max-width: 768px) {
  .mobileInternet {
    .mobileInternetP2-mobile {
      .swiper_bg_blue {
        width: 5rem;
        margin-top: 0.67rem;
      }
    }
    .p6_m_mobile {
      .swiper-pagination {
        width: 6.14rem;
        margin-top: 0.78rem;
        .swiper-pagination-bullet {
          box-sizing: border-box;
          width: 0.18rem;
          height: 0.18rem;
          border: 0.01rem solid #ffffff;
          background: linear-gradient(0deg, #316ebc 0%, #005cb9 100%);
          border-radius: 50%;
          margin: 0px 0.15rem;
          opacity: 1;
        }
        .swiper-pagination-bullet-active {
          border: 0.02rem solid #005cb9;
          background: #f1f1f1;
        }
      }
    }
  }
}
</style>
