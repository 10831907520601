<template>
  <div class="Transportation">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>交通运输解决方案</span>
          <span>交通运输<br />解决方案</span>
          <p>
            提供数字化管理的共享服务平台，助力行业客户降低运营成本，提升服务效率。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/solution/transportation/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <PartFlexSwiper
      :pFlexSwiperData="p2Data"
      :pFlexSwiperDatas="p2Datas"
      :pFlexSwiperTitle="p2Title"
    ></PartFlexSwiper>
    <PartCore ref="Part3Core" :pCoreData="p3Data"></PartCore>
    <PartSupport :pSData="p4Data" :pSDData="p4DData"></PartSupport>
    <section class="part5">
      <span class="part-title">解决方案结构</span>
      <div class="p5_main reveal-bottom">
        <div class="p5_up">
          <span
            v-text="item.span"
            v-for="(item, i) in p5Data"
            :key="i"
            @click="p5Click(i, item)"
          ></span>
        </div>
        <div class="p5_down" v-if="p5DataItem.chance == 0">
          <div class="p5_dItem" v-for="(item, i) in p5DataItem.data" :key="i">
            <img :src="item.img" alt="" />
            <div>
              <span v-text="item.span"></span>
              <p v-text="item.p"></p>
            </div>
          </div>
        </div>
        <div class="p5_down" v-else>
          <img :src="p5DataItem.img" alt="" />
          <div class="p5_right">
            <p v-text="p5DataItem.p"></p>
            <div class="p5_rLeft">
              <div
                class="p5_rLItem"
                v-for="(item, i) in p5DataItem.leftData"
                :key="i"
                @mousemove="p5ItemClick(i)"
              >
                <span v-html="item.span"></span>
              </div>
            </div>
            <div class="p5_rRight">
              <div
                class="p5_rRItem"
                v-for="(item, i) in p5DataItem.leftData"
                :key="i"
                @mousemove="p5ItemClick(i)"
              >
                <p v-text="item.p"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <PartImgSwiper
      ref="part6Img"
      :pImgData="p6Data"
      :pImgDatas="p6Datas"
    ></PartImgSwiper>
  </div>
</template>

<script>
import PartFlexSwiper from '@/components/solution/PartFlexSwiper.vue';
import PartCore from '@/components/solution/PartCore.vue';
import PartSupport from '@/components/solution/PartSupport.vue';
import PartImgSwiper from '@/components/solution/PartImgSwiper.vue';
export default {
  data() {
    return {
      // p2
      p2Data: [
        {
          img: require('../../assets/img/solution/transportation/part2-icon1.png'),
          imgOver: require('../../assets/img/solution/transportation/part2-icon1-over.png'),
          span: '企业统一沟通渠道',
          p: '交通运输行业客户面向着全国上亿的个人用户及消费者，且与用户每天都建立着密切的联系与往来，需要建立一个统一的企业信息窗口，方便与用户的信息联系，同时可提升企业的对外形象，提升交通运输行业客户的服务质量。',
        },
        {
          img: require('../../assets/img/solution/transportation/part2-icon2.png'),
          imgOver: require('../../assets/img/solution/transportation/part2-icon2-over.png'),
          span: '国际化运输管理',
          p: '随着全球化趋势的日趋扩大，交通运输行业承载着大量的国际化信息传输，需要完善的高效、安全的国际系统服务支撑，助力提升国际化服务质量，同时降低跨境运输的运营成本，形成一套完善的全球服务管理体系。',
        },
        {
          img: require('../../assets/img/solution/transportation/part2-icon3.png'),
          imgOver: require('../../assets/img/solution/transportation/part2-icon3-over.png'),
          span: '用户隐私安全保障',
          p: '交通运输行业大量、高频地链接着陌生用户之间的联系，用户信息安全与个人隐私安全尤其重要，需要依靠更生态化的支撑供应，以技术的手段保障用户信息不被泄露，提供人性化、非透明化的连接服务。',
        },
        {
          img: require('../../assets/img/solution/transportation/part2-icon4.png'),
          imgOver: require('../../assets/img/solution/transportation/part2-icon4-over.png'),
          span: '数据信息管理',
          p: '交通运输行业客户每天、每月、每年都需要面对大量的商品、乘客、数据等繁杂的信息管理，且信息处于不断地更新交替状态，极大地耗费及占据人力、经济和管理成本，需要一套智能化的管理平台，对各项信息进行实时处理、跟踪和综合性管理。',
        },
      ],
      p2Datas: [],
      p2Title: '行业需求',
      // p3
      p3Data: [
        {
          img: require('../../assets/img/solution/transportation/part3-icon1.png'),
          img2: require('../../assets/img/solution/transportation/mobile/part3-icon1.png'),
          imgOver: require('../../assets/img/solution/transportation/part3-icon1-over.png'),
          span: '实时短信通知',
          p: '实时的短信通知/验证保障交通运输信息的及时性、安全性',
        },
        {
          img: require('../../assets/img/solution/transportation/part3-icon2.png'),
          img2: require('../../assets/img/solution/transportation/mobile/part3-icon2.png'),
          imgOver: require('../../assets/img/solution/transportation/part3-icon2-over.png'),
          span: '安全呼叫中心',
          p: '安全呼叫中心构建交通运输行业用户安全便捷、高效的信息沟通',
        },
        {
          img: require('../../assets/img/solution/transportation/part3-icon3.png'),
          img2: require('../../assets/img/solution/transportation/mobile/part3-icon3.png'),
          imgOver: require('../../assets/img/solution/transportation/part3-icon3-over.png'),
          span: 'M2M通信',
          p: '实现物与物的实时连接构建交通运输载体智能化通信',
        },
        {
          img: require('../../assets/img/solution/transportation/part3-icon4.png'),
          img2: require('../../assets/img/solution/transportation/mobile/part3-icon4.png'),
          imgOver: require('../../assets/img/solution/transportation/part3-icon4-over.png'),
          span: '设备状态管理',
          p: '对交通运输设备进行实时的状态监控及管理智能化运输服务',
        },
        {
          img: require('../../assets/img/solution/transportation/part3-icon5.png'),
          img2: require('../../assets/img/solution/transportation/mobile/part3-icon5.png'),
          imgOver: require('../../assets/img/solution/transportation/part3-icon5-over.png'),
          span: '车联网',
          p: '实现车辆位置、速度和路线等信息的连接智能化车联网服务',
        },
        {
          img: require('../../assets/img/solution/transportation/part3-icon6.png'),
          img2: require('../../assets/img/solution/transportation/mobile/part3-icon6.png'),
          imgOver: require('../../assets/img/solution/transportation/part3-icon6-over.png'),
          span: '物流云',
          p: '仓储、配送、管理一体化一站式云平台物流管理',
        },
      ],
      // p4
      p4Data: [
        {
          title: '国际短信',
          img: require('../../assets/img/solution/transportation/part4-icon1.png'),
          p: '国际短信有着准确率高、收发功能齐全、价格合理等方面的优势，一套成熟的国际短信平台对于企业客户开展海外业务极其重要，百悟国际短信平台为交通运输行业客户提供着稳定高效的海外支持。',
        },
        {
          title: '95语音',
          img: require('../../assets/img/solution/transportation/part4-icon2.png'),
          p: '百悟可为交通运输行业客户提供专属定制的95语音客户服务热线的全套解决方案，包含申请、备案、数据及上线等系列工作，助力交通运输行业客户打造安全、高效、便捷的统一客户服务热线服务',
        },
        {
          title: '安全小号',
          img: require('../../assets/img/solution/transportation/part4-icon3.png'),
          p: '百悟安全小号，致力于构建企业安全呼叫体系。可为交通运输行业客户构建多体系的安全呼叫，实现多方位的商品信息、用户数据保护体系，防范数据丢失及相关隐私泄露的问题发生，让企业无为而为茁壮发展。',
        },
        {
          title: '物联网',
          img: require('../../assets/img/solution/transportation/part4-icon4.png'),
          p: '基于先进的信息技术、数据通讯传输技术、电子传感技术、控制技术等，于地面建立一种在大范围内、全方位发挥作用的，实时、高效的综合交通运输管理系统，为交通运输参与者提供多样性的服务。',
        },
      ],
      p4DData: [
        {
          img: require('../../assets/img/solution/transportation/part4-img1.png'),
          imgOver: require('../../assets/img/solution/transportation/part4-img1-over.png'),
          span: '方案灵活<br>可根据客户需要个性化定制',
        },
        {
          img: require('../../assets/img/solution/transportation/part4-img2.png'),
          imgOver: require('../../assets/img/solution/transportation/part4-img2-over.png'),
          span: '安全保障<br>运营商级的资源及技术保障',
        },
        {
          img: require('../../assets/img/solution/transportation/part4-img3.png'),
          imgOver: require('../../assets/img/solution/transportation/part4-img3-over.png'),
          span: '快速响应<br>专业团队快速响应用户需求',
        },
      ],
      // p5
      p5Data: [
        {
          chance: 0,
          span: '安全交通运输',
          data: [
            {
              img: require('../../assets/img/solution/transportation/part5-icon1.png'),
              span: '打车服务',
              p: '以订单作为绑定依据，按需生成安全小号(中间号)，司机及乘客双方通过该安全小号(中间号)进行互拨通话。订单完成，绑定关系解除。',
            },
            {
              img: require('../../assets/img/solution/transportation/part5-icon2.png'),
              span: '物流派送服务',
              p: '快递派送即为快递员和买家分配一个安全小号(中间号)，派件期间通过该号码进行通话，物品派送成功，绑定关系随即解除。',
            },
          ],
        },
        {
          chance: 1,
          span: '智慧交通运输',
          img: require('../../assets/img/solution/transportation/part5-icon3.png'),
          p: '百悟物联网服务为交通运输行业专属定制智慧运输解决方案，通过物联网卡的集成短信、语音和流量等服务，实现管理、监测、控制的物联网管理平台服务，打造智慧化交通运输。',
          leftData: [
            {
              span: '智能车载系统',
              p: '故障诊断、油耗分析、驾驶行为优化、位置服务、违章查询、车友交互。',
            },
            {
              span: '车载产品软件',
              p: '车载通讯设备、车联网数据处理、信息传输服务、语音导航服务。',
            },
            {
              span: '物流智能终端',
              p: '快递员把枪、快递车辆GPS定位。',
            },
          ],
        },
      ],
      p5DataItem: {},
      // p6
      p6Data: [
        {
          img: require('../../assets/img/solution/transportation/part6-icon1.png'),
          imgOver: require('../../assets/img/solution/transportation/part6-icon1-over.png'),
        },
        {
          img: require('../../assets/img/solution/transportation/part6-icon2.png'),
          imgOver: require('../../assets/img/solution/transportation/part6-icon2-over.png'),
        },
        {
          img: require('../../assets/img/solution/transportation/part6-icon3.png'),
          imgOver: require('../../assets/img/solution/transportation/part6-icon3-over.png'),
        },
      ],
      p6Datas: [],
    };
  },
  components: {
    PartFlexSwiper,
    PartCore,
    PartSupport,
    PartImgSwiper,
  },
  mounted() {
    // 数据初始化
    this.p2Datas = [
      JSON.parse(JSON.stringify(this.p2Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p2Data.slice(-2))),
    ];
    this.p6Datas = [
      JSON.parse(JSON.stringify(this.p6Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p6Data.slice(-1))),
    ];
    // 状态初始化
    this.start();
  },
  methods: {
    // 初始化
    start() {
      this.$refs.Part3Core.pCoreMove(0, this.p3Data[0].imgOver);
      this.$refs.Part3Core.pCoreClick(0, this.p3Data[0].img2, this.p3Data[0]);
      this.p5Click(0, this.p5Data[0]);
      this.$refs.part6Img.pImgClick(0, this.p6Data[0].imgOver);
    },
    p5Click(i, item) {
      this.p5DataItem = item;
      $('.p5_up span').removeClass('active');
      $('.p5_up span').eq(i).addClass('active');
    },
    p5ItemClick(i) {
      $('.p5_rLItem').removeClass('active');
      $('.p5_rRItem').removeClass('active');
      $('.p5_rLItem').eq(i).addClass('active');
      $('.p5_rRItem').eq(i).addClass('active');
    },
    p6Click(i, img) {
      $('.p6_item').each((index, item) => {
        $(item).find('img').attr('src', this.p6Data[index].img);
      });
      $('.p6_item').removeClass('active');
      $('.p6_item').eq(i).find('img').attr('src', img);
    },
  },
  watch: {
    p5DataItem(newVal, oldVal) {
      if (newVal.chance == 1) {
        setTimeout(() => {
          this.p5ItemClick(0);
        }, 100);
      }
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 723px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      position: absolute;
      width: 858px;
      height: 719px;
      z-index: -1;
      top: 4px;
      right: -313px;
    }
  }
  .part1-title {
    width: 538px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    span:nth-of-type(2) {
      display: none;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 67px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part5 {
  width: 100%;
  padding-top: 79px;
  padding-bottom: 128px;
  background: url('../../assets/img/solution/finance/part5-bg.png') no-repeat;
  background-size: 100% 100%;
  .part-title {
    color: #ffffff;
    margin-bottom: 66px;
  }
  .p5_main {
    width: 1113px;
    margin: 0 auto;
    height: 718px;
    background: rgba(241, 241, 241, 0.92);
    border-radius: 7px;
    .p5_up {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      height: 109px;
      padding: 0px 160px;
      span {
        font-size: 24px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        position: relative;
      }
      span::after {
        display: block;
        content: '';
        position: absolute;
        top: 10px;
        left: -26px;
        width: 10px;
        height: 10px;
        background: #0b63bb;
        border-radius: 50%;
        display: none;
      }
      span.active {
        color: #0b63bb;
      }
      span.active::after {
        display: block;
      }
    }
    .p5_down {
      box-sizing: border-box;
      width: 100%;
      height: 718px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 27px;
      .p5_dItem {
        width: 513px;
        height: 583px;
        border-radius: 7px;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        > div {
          width: 464px;
          height: 156px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: 24px;
          left: 24px;
          span {
            font-size: 24px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 7px;
          }
          p {
            display: block;
            width: 398px;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            line-height: 27px;
            text-align: justify;
            text-align-last: center;
          }
        }
      }
      > img {
        width: 609px;
        height: 582px;
      }
      .p5_right {
        box-sizing: border-box;
        width: 437px;
        height: 583px;
        border: 2px solid #e4e6e9;
        border-radius: 7px;
        background: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        > p {
          display: block;
          position: absolute;
          width: 351px;
          font-size: 16px;
          line-height: 27px;
          font-weight: bold;
          color: #333333;
          text-align: justify;
          top: 40px;
          left: 37px;
        }
        .p5_rLeft {
          box-sizing: border-box;
          width: 177px;
          height: 386px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-bottom: 16px;
          border-right: 1px solid #e4e6e9;
          margin-top: 195px;
          .p5_rLItem {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            span {
              font-size: 24px;
              font-family: FontExcellent;
              font-weight: 400;
              color: #333333;
            }
          }
          .p5_rLItem.active {
            span {
              color: #005cb9;
              position: relative;
            }
            span::after {
              content: '';
              display: block;
              width: 4px;
              height: 38px;
              background: #005cb9;
              position: absolute;
              top: -3px;
              left: -13px;
            }
          }
        }
        .p5_rRight {
          box-sizing: border-box;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-top: 195px;
          .p5_rRItem {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            p {
              display: block;
              width: 196px;
              text-align: justify;
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              line-height: 27px;
            }
          }
          .p5_rRItem.active {
            p {
              font-weight: bold;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
.part6 {
  width: 100%;
  padding-top: 78px;
  padding-bottom: 119px;
  .part-title {
    margin-bottom: 86px;
  }
  .p6_main {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .p6_item {
      box-sizing: border-box;
      width: 344px;
      height: 162px;
      background: #ffffff;
      border: 2px solid #f1f1f1;
      margin-bottom: 44px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .p6_item.active {
      border: 2px solid #0b63bb;
    }
  }
}
@media screen and (max-width: 768px) {
  /* 第一部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        content: url('../../assets/img/solution/transportation/mobile/part1-icon.png');
        width: 5.28rem;
        height: 4.65rem;
        top: 0.05rem;
        right: -0.61rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
        margin-top: -0.62rem;
      }
      span:nth-of-type(1) {
        display: none;
      }
      span:nth-of-type(2) {
        display: block;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part5 {
    padding-top: 0.58rem;
    padding-bottom: 1.06rem;
    background: url('../../assets/img/solution/finance/mobile/part5-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 0.69rem;
    }
    .p5_main {
      width: 7.09rem;
      height: auto;
      padding-bottom: 0.2rem;
      border-radius: 0.07rem;
      .p5_up {
        height: 0.89rem;
        padding: 0px 0.85rem;
        span {
          font-size: 0.24rem;
        }
        span::after {
          top: 0.1rem;
          left: -0.26rem;
          width: 0.1rem;
          height: 0.1rem;
        }
      }
      .p5_down {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 0px;
        .p5_dItem {
          width: 6.13rem;
          height: 6.27rem;
          background: #fafbfc;
          border-radius: 0.07rem;
          justify-content: center;
          margin-bottom: 0.39rem;
          img {
            width: 4.88rem;
          }
          > div {
            width: 4.64rem;
            height: 1.56rem;
            bottom: 0.48rem;
            left: 0.73rem;
            span {
              font-size: 0.24rem;
              margin-bottom: 0.07rem;
            }
            p {
              width: 3.98rem;
              font-size: 0.16rem;
              line-height: 0.27rem;
            }
          }
        }
        > img {
          width: 6.09rem;
          height: 5.82rem;
          margin-bottom: 0.25rem;
        }
        .p5_right {
          width: 6.09rem;
          height: 5.83rem;
          border: 0.02rem solid #e4e6e9;
          border-radius: 0.07rem;
          margin-bottom: 0.25rem;
          > p {
            width: 5rem;
            font-size: 0.16rem;
            line-height: 0.27rem;
            top: 0.4rem;
            left: 0.37rem;
          }
          .p5_rLeft {
            width: 1.77rem;
            height: 3.86rem;
            padding-bottom: 0.2rem;
            margin-top: 1.95rem;
            .p5_rLItem {
              span {
                font-size: 0.24rem;
              }
            }
            .p5_rLItem.active {
              span::after {
                width: 0.04rem;
                height: 0.38rem;
                top: -0.03rem;
                left: -0.13rem;
              }
            }
          }
          .p5_rRight {
            padding-top: 1.95rem;
            .p5_rRItem {
              p {
                width: 3rem;
                font-size: 0.16rem;
                line-height: 0.27rem;
              }
            }
          }
        }
      }
    }
  }
  .part6 {
    width: 100%;
    padding-top: 0.56rem;
    padding-bottom: 0.81rem;
    .part-title {
      margin-bottom: 0.51rem;
    }
    .p6_main {
      width: 6.14rem;
      .p6_item {
        width: 2.91rem;
        height: 1.37rem;
        border: 0.02rem solid #f1f1f1;
        margin-bottom: 0.23rem;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .p6_item.active {
        border: 0.02rem solid #0b63bb;
      }
    }
  }
}
</style>
<style lang="less">
.Transportation {
  .partSupport .pS_main .pS_dTwo .pS_dTItem span {
    text-align: center;
  }
  .partFlexSwiper {
    background: url('../../assets/img/solution/transportation/part2-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 78px;
    }
    .partFlexSwiper-item {
      width: 256px !important;
      height: 458px !important;
      padding-top: 39px !important;
      > div {
        height: 89px !important;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .Transportation {
    .partFlexSwiper {
      background: url('../../assets/img/solution/transportation/mobile/part2-bg.png')
        no-repeat;
      background-size: 100% 100%;
      .part-title {
        margin-bottom: 0.78rem;
      }
      .partFlexSwiper-item {
        width: 2.56rem !important;
        height: 4.58rem !important;
        padding-top: 0.39rem !important;
        > div {
          height: 0.89rem !important;
        }
      }
    }
  }
}
</style>
