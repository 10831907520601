<template>
  <div class="finance">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>金融解决方案</span>
          <p>
            百悟科技为互联网金融、银行、证券等行业提供包括安全、合规等在内的完备产品和服务，助力金融机构实现云端部署、进行业务创新。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/solution/finance/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <PartFlexSwiper
      :pFlexSwiperData="p2Data"
      :pFlexSwiperDatas="p2Datas"
      :pFlexSwiperTitle="p2Title"
    ></PartFlexSwiper>
    <PartCore ref="Part3Core" :pCoreData="p3Data"></PartCore>
    <PartSupport :pSData="p4Data" :pSDData="p4DData"></PartSupport>
    <section class="part5">
      <span class="part-title">解决方案结构</span>
      <div class="p5_main">
        <div class="p5_up">
          <span
            v-text="item.span"
            v-for="(item, i) in p5Data"
            :key="i"
            @click="p5Click(i, item)"
          ></span>
        </div>
        <div class="p5_down">
          <img :src="p5DataItem.img" alt="" />
          <div class="p5_right">
            <div class="p5_rLeft">
              <div
                class="p5_rLItem"
                v-for="(item, i) in p5DataItem.leftData"
                :key="i"
                @mousemove="p5ItemClick(i)"
              >
                <span v-html="item.span"></span>
              </div>
            </div>
            <div class="p5_rRight">
              <div
                class="p5_rRItem"
                v-for="(item, i) in p5DataItem.leftData"
                :key="i"
                @mousemove="p5ItemClick(i)"
              >
                <p v-text="item.p"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="part6">
      <span class="part-title">典型案例</span>
      <div class="p6_main">
        <div
          class="p6_item"
          v-for="(item, i) in p6Data"
          :key="i"
          @mousemove="p6Click(i, item.imgOver)"
        >
          <img :src="item.img" alt="" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PartFlexSwiper from '@/components/solution/PartFlexSwiper.vue';
import PartCore from '@/components/solution/PartCore.vue';
import PartSupport from '@/components/solution/PartSupport.vue';
export default {
  data() {
    return {
      // p2
      swiperOption: {
        pagination: {
          el: '.governmentAndEnterpriseP2-swiper .swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
        loop: false,
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。
      },
      p2Data: [
        {
          img: require('../../assets/img/solution/finance/part2-icon1.png'),
          imgOver: require('../../assets/img/solution/finance/part2-icon1-over.png'),
          span: '企业统一形象树立',
          p: '无论是传统金融企业，还是新兴互联网金融，均需要具备一定权威性的统一对外企业形象与信息窗口，为与用户的沟通构建一条权威、畅达的信息通道，体现金融企业品牌的专业性以及易识别性。',
        },
        {
          img: require('../../assets/img/solution/finance/part2-icon2.png'),
          imgOver: require('../../assets/img/solution/finance/part2-icon2-over.png'),
          span: '安全信息沟通渠道',
          p: '金融行业涉及诸多个人用户的个人钱款信息、个人身份信息等隐私信息，需要建立一套安全的信息沟通渠道，避免重要信息泄露、金融诈骗等问题，保障金融客户的正常运营、金融用户的个人信息安全。',
        },
        {
          img: require('../../assets/img/solution/finance/part2-icon3.png'),
          imgOver: require('../../assets/img/solution/finance/part2-icon3-over.png'),
          span: '应对业务快速发展',
          p: '随着各行业互联网化发展的趋势，按照传统思路部署的网络、计算资源、存储资源不足以支撑金融行业互联网业务的开展，需要增强资源弹性，加快资源获取和部署的速度，以应对开展新金融业务所需要的基础服务。',
        },
        {
          img: require('../../assets/img/solution/finance/part2-icon4.png'),
          imgOver: require('../../assets/img/solution/finance/part2-icon4-over.png'),
          span: '应对海量数据处理',
          p: '金融行业涉及着海量的用户数据、交易数据、信用数据等，同时随着互联网业务形态的变化，互联网渠道所带来的高并发、大规模，需要应对海量数据对传统核心系统造成的巨大冲击，保障数据信息畅通和安全。',
        },
      ],
      p2Datas: [],
      p2Title: '行业需求',
      // p3
      p3Data: [
        {
          img: require('../../assets/img/solution/finance/part3-icon1.png'),
          img2: require('../../assets/img/solution/finance/mobile/part3-icon1.png'),
          imgOver: require('../../assets/img/solution/finance/part3-icon1-over.png'),
          span: '企业客服服务热线',
          p: '为金融客户建立统一的企业客户服务热线树立安全可靠的统一对外形象',
        },
        {
          img: require('../../assets/img/solution/finance/part3-icon2.png'),
          img2: require('../../assets/img/solution/finance/mobile/part3-icon2.png'),
          imgOver: require('../../assets/img/solution/finance/part3-icon2-over.png'),
          span: '加密保障',
          p: '百悟云融合金融硬件加密机USBKEY等安全设备对文件进行安全处理',
        },
        {
          img: require('../../assets/img/solution/finance/part3-icon3.png'),
          img2: require('../../assets/img/solution/finance/mobile/part3-icon3.png'),
          imgOver: require('../../assets/img/solution/finance/part3-icon3-over.png'),
          span: '私有云-软件授权',
          p: '获得云平台管理软件授权便捷地运行云系统',
        },
        {
          img: require('../../assets/img/solution/finance/part3-icon4.png'),
          img2: require('../../assets/img/solution/finance/mobile/part3-icon4.png'),
          imgOver: require('../../assets/img/solution/finance/part3-icon4-over.png'),
          span: '语音/短信验证码',
          p: '即时的语音/短信验证码保障金融信息安全',
        },
        {
          img: require('../../assets/img/solution/finance/part3-icon5.png'),
          img2: require('../../assets/img/solution/finance/mobile/part3-icon5.png'),
          imgOver: require('../../assets/img/solution/finance/part3-icon5-over.png'),
          span: '精准广告推送',
          p: '面向用户进行精准金融广告推送，提升业务量',
        },
        {
          img: require('../../assets/img/solution/finance/part3-icon6.png'),
          img2: require('../../assets/img/solution/finance/mobile/part3-icon6.png'),
          imgOver: require('../../assets/img/solution/finance/part3-icon6-over.png'),
          span: '智能金融服务',
          p: '更准确地进行用户评估金融拓展、业务维护等，金融服务智能化',
        },
      ],
      // p4
      p4Data: [
        {
          title: '95语音平台',
          img: require('../../assets/img/solution/finance/part4-icon1.png'),
          p: '百悟科技95语音服务，协助金融企业客户完成业务申请、平台搭建、运维支撑等工作，实现统一的企业95语音云通信服务，帮助金融客户实现品牌升级。',
        },
        {
          title: '物联网平台',
          img: require('../../assets/img/solution/finance/part4-icon2.png'),
          p: '百悟科技物联网平台，为金融行业客户定制专属的集物联网卡、物联网管理平台、物联网产业支撑一体的物联网服务，助力打造智慧金融。',
        },
        {
          title: '大数据平台',
          img: require('../../assets/img/solution/finance/part4-icon3.png'),
          p: '百悟科技大数据，面向银行客户的风险控制与精准营销、保险客户的老客维系和新客获取，互联网金融的风控支撑与运营决策，形成金融行业大数据风控、运营、营销一体的产品服务。',
        },
      ],
      p4DData: [
        {
          img: require('../../assets/img/solution/finance/part4-img1.png'),
          imgOver: require('../../assets/img/solution/finance/part4-img1-over.png'),
          span: '不触碰数据源，安全可靠',
        },
        {
          img: require('../../assets/img/solution/finance/part4-img2.png'),
          imgOver: require('../../assets/img/solution/finance/part4-img2-over.png'),
          span: '运营商级资源，品质保障',
        },
        {
          img: require('../../assets/img/solution/finance/part4-img3.png'),
          imgOver: require('../../assets/img/solution/finance/part4-img3-over.png'),
          span: '自主研发平台，灵活应对',
        },
      ],
      // p5
      p5Data: [
        {
          span: '实时信息沟通',
          img: require('../../assets/img/solution/finance/part5-icon1.png'),
          leftData: [
            {
              span: '安全',
              p: '百悟云融合金融硬件加密机、USBKEY等安全设备对文件进行安全处理。',
            },
            {
              span: '选择',
              p: '在加密和文件传输方面，系统支持多少红方式，客户可以根据实际情况灵活选择不同配置。',
            },
            {
              span: '传输',
              p: '支持一对一、一对多、多对多的传输功能。',
            },
            {
              span: '密钥',
              p: '客户端与传输保护密钥一一绑定而且每个客户端的传输保护密钥各不相同。',
            },
          ],
        },
        {
          span: '网络加速支撑',
          img: require('../../assets/img/solution/finance/part5-icon2.png'),
          leftData: [
            {
              span: '大数据验真',
              p: '基于价值评估，为金融客户提供身份核验、在网大数据、信用评分等安全、快捷的信息验证服务。',
            },
            {
              span: '大数据分析',
              p: '基于运营商大数据能力、通过GIS等多种手段，实现金融宏观市场分析以及渠道布局的判断。',
            },
            {
              span: '大数据<br>精准营销',
              p: '根据金融客户需求，通过为行业客户定制的基础标签及专属模型标签的组合，精准筛选目标用户群，提升客户维系的效果。',
            },
          ],
        },
      ],
      p5DataItem: {},
      // p6
      p6Data: [
        {
          img: require('../../assets/img/solution/finance/part6-icon1.png'),
          imgOver: require('../../assets/img/solution/finance/part6-icon1-over.png'),
        },
        {
          img: require('../../assets/img/solution/finance/part6-icon2.png'),
          imgOver: require('../../assets/img/solution/finance/part6-icon2-over.png'),
        },
        {
          img: require('../../assets/img/solution/finance/part6-icon3.png'),
          imgOver: require('../../assets/img/solution/finance/part6-icon3-over.png'),
        },
        {
          img: require('../../assets/img/solution/finance/part6-icon4.png'),
          imgOver: require('../../assets/img/solution/finance/part6-icon4-over.png'),
        },
        {
          img: require('../../assets/img/solution/finance/part6-icon5.png'),
          imgOver: require('../../assets/img/solution/finance/part6-icon5-over.png'),
        },
        {
          img: require('../../assets/img/solution/finance/part6-icon6.png'),
          imgOver: require('../../assets/img/solution/finance/part6-icon6-over.png'),
        },
      ],
    };
  },
  components: {
    PartFlexSwiper,
    PartCore,
    PartSupport,
  },
  mounted() {
    // 数据初始化
    this.p2Datas = [
      JSON.parse(JSON.stringify(this.p2Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p2Data.slice(-2))),
    ];
    // 状态初始化
    this.start();
  },
  methods: {
    // 初始化
    start() {
      this.$refs.Part3Core.pCoreMove(0, this.p3Data[0].imgOver);
      this.$refs.Part3Core.pCoreClick(0, this.p3Data[0].img2, this.p3Data[0]);
      this.p5Click(0, this.p5Data[0]);
      setTimeout(() => {
        this.p5ItemClick(0);
      }, 100);
      this.p6Click(0, this.p6Data[0].imgOver);
    },
    p5Click(i, item) {
      this.p5DataItem = item;
      $('.p5_up span').removeClass('active');
      $('.p5_up span').eq(i).addClass('active');
    },
    p5ItemClick(i) {
      $('.p5_rLItem').removeClass('active');
      $('.p5_rRItem').removeClass('active');
      $('.p5_rLItem').eq(i).addClass('active');
      $('.p5_rRItem').eq(i).addClass('active');
    },
    p6Click(i, img) {
      $('.p6_item').each((index, item) => {
        $(item).find('img').attr('src', this.p6Data[index].img);
      });
      $('.p6_item').removeClass('active');
      $('.p6_item').eq(i).find('img').attr('src', img);
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 723px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      position: absolute;
      width: 731px;
      height: 689px;
      z-index: -1;
      top: 34px;
      right: -201px;
    }
  }
  .part1-title {
    width: 538px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 67px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part5 {
  width: 100%;
  padding-top: 79px;
  padding-bottom: 128px;
  background: url('../../assets/img/solution/finance/part5-bg.png') no-repeat;
  background-size: 100% 100%;
  .part-title {
    color: #ffffff;
    margin-bottom: 66px;
  }
  .p5_main {
    width: 1111px;
    margin: 0 auto;
    height: 718px;
    background: rgba(241, 241, 241, 0.92);
    border-radius: 7px;
    .p5_up {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      height: 109px;
      padding: 0px 160px;
      span {
        font-size: 24px;
        font-family: FontExcellent;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        position: relative;
      }
      span::after {
        display: block;
        content: '';
        position: absolute;
        top: 10px;
        left: -26px;
        width: 10px;
        height: 10px;
        background: #0b63bb;
        border-radius: 50%;
        display: none;
      }
      span.active {
        color: #0b63bb;
      }
      span.active::after {
        display: block;
      }
    }
    .p5_down {
      width: 100%;
      height: 577px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .p5_right {
        box-sizing: border-box;
        width: 434px;
        height: 579px;
        border: 2px solid #e4e6e9;
        border-radius: 7px;
        background: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .p5_rLeft {
          box-sizing: border-box;
          width: 154px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-bottom: 16px;
          border-right: 1px solid #e4e6e9;
          .p5_rLItem {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            span {
              font-size: 24px;
              font-family: FontExcellent;
              font-weight: 400;
              color: #333333;
            }
          }
          .p5_rLItem.active {
            span {
              color: #005cb9;
              position: relative;
            }
            span::after {
              content: '';
              display: block;
              width: 4px;
              height: 38px;
              background: #005cb9;
              position: absolute;
              top: -3px;
              left: -13px;
            }
          }
        }
        .p5_rRight {
          box-sizing: border-box;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .p5_rRItem {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            p {
              display: block;
              width: 196px;
              text-align: justify;
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              line-height: 27px;
            }
          }
          .p5_rRItem.active {
            p {
              font-weight: bold;
              color: #333333;
            }
          }
        }
      }
    }
  }
}
.part6 {
  width: 100%;
  padding-top: 78px;
  padding-bottom: 119px;
  .part-title {
    margin-bottom: 86px;
  }
  .p6_main {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .p6_item {
      box-sizing: border-box;
      width: 344px;
      height: 162px;
      background: #ffffff;
      border: 2px solid #f1f1f1;
      margin-bottom: 44px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .p6_item.active {
      border: 2px solid #0b63bb;
    }
  }
}
@media screen and (max-width: 768px) {
  /* 第一部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        content: url('../../assets/img/solution/finance/mobile/part1-icon.png');
        width: 4.51rem;
        height: 4.06rem;
        top: 0.22rem;
        right: -0.27rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
        margin-top: -0.62rem;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part5 {
    padding-top: 0.58rem;
    padding-bottom: 1.06rem;
    background: url('../../assets/img/solution/finance/mobile/part5-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 0.69rem;
    }
    .p5_main {
      width: 7.09rem;
      height: 11.42rem;
      border-radius: 0.07rem;
      .p5_up {
        height: 0.89rem;
        padding: 0px 0.85rem;
        span {
          font-size: 0.24rem;
        }
        span::after {
          top: 0.1rem;
          left: -0.26rem;
          width: 0.1rem;
          height: 0.1rem;
        }
      }
      .p5_down {
        height: auto;
        flex-direction: column;
        align-items: center;
        > img {
          width: 6.47rem;
          height: 5.77rem;
          margin-bottom: 0.25rem;
        }
        .p5_right {
          width: 6.47rem;
          height: 4.27rem;
          border: 0.02rem solid #e4e6e9;
          border-radius: 0.07rem;
          .p5_rLeft {
            width: 1.22rem;
            padding-bottom: 0.16rem;
            .p5_rLItem {
              span {
                font-size: 0.21rem;
              }
            }
            .p5_rLItem.active {
              span::after {
                width: 0.04rem;
                height: 0.38rem;
                top: -0.03rem;
                left: -0.13rem;
              }
            }
          }
          .p5_rRight {
            .p5_rRItem {
              p {
                width: 4.5rem;
                font-size: 0.15rem;
                line-height: 0.27rem;
              }
            }
          }
        }
      }
    }
  }
  .part6 {
    width: 100%;
    padding-top: 0.56rem;
    padding-bottom: 0.81rem;
    .part-title {
      margin-bottom: 0.51rem;
    }
    .p6_main {
      width: 6.14rem;
      .p6_item {
        width: 2.91rem;
        height: 1.37rem;
        border: 0.02rem solid #f1f1f1;
        margin-bottom: 0.23rem;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .p6_item.active {
        border: 0.02rem solid #0b63bb;
      }
    }
  }
}
</style>
<style lang="less">
.finance {
  .partFlexSwiper {
    background: url('../../assets/img/solution/finance/part2-bg.png') no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 78px;
    }
    .partFlexSwiper-item {
      width: 256px !important;
      height: 458px !important;
      padding-top: 49px !important;
      > div {
        height: 85px !important;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .finance {
    .partFlexSwiper {
      background: url('../../assets/img/solution/finance/mobile/part2-bg.png')
        no-repeat;
      background-size: 100% 100%;
      .part-title {
        margin-bottom: 0.78rem;
      }
      .partFlexSwiper-item {
        width: 2.56rem !important;
        height: 4.58rem !important;
        padding-top: 0.49rem !important;
        > div {
          height: 0.85rem !important;
        }
      }
    }
  }
}
</style>
