<template>
  <div class="O2O">
    <section class="part1">
      <div class="part1_main">
        <div class="part1-title reveal-left">
          <span>O2O解决方案</span>
          <p>
            针对各行业业务特性，打造个性化行业解决方案，为用户提供一站式的产品与服务，助力O2O系统搭建合理的生态体系。
          </p>
          <a class="zhiCustomBtn">了解更多</a>
        </div>
        <img
          class="reveal-right"
          src="../../assets/img/solution/O2O/part1-icon.png"
          alt=""
        />
      </div>
    </section>
    <PartFlexSwiper
      :pFlexSwiperData="p2Data"
      :pFlexSwiperDatas="p2Datas"
      :pFlexSwiperTitle="p2Title"
    ></PartFlexSwiper>
    <PartCore ref="Part3Core" :pCoreData="p3Data"></PartCore>
    <PartAdvantage
      :pAdvantageData="p4Data"
      :pAdvantageDatas="p4Datas"
    ></PartAdvantage>
    <section class="part5">
      <span class="part-title">解决方案结构</span>
      <div class="p5_main reveal-bottom2">
        <img src="../../assets/img/solution/O2O/part5-icon.png" alt="" />
        <div class="p5_right">
          <div class="p5_rLeft">
            <div
              class="p5_rLItem"
              v-for="(item, i) in p5Data"
              :key="i"
              @mousemove="p5Click(i)"
            >
              <span v-html="item.span"></span>
            </div>
          </div>
          <div class="p5_rRight">
            <div
              class="p5_rRItem"
              v-for="(item, i) in p5Data"
              :key="i"
              @mousemove="p5Click(i)"
            >
              <p v-text="item.p"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="part6">
      <span class="part-title">典型案例</span>
      <div class="p6_main">
        <div
          class="p6_item"
          v-for="(item, i) in p6Data"
          :key="i"
          @mousemove="p6Click(i, item.imgOver)"
        >
          <img :src="item.img" alt="" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PartFlexSwiper from '@/components/solution/PartFlexSwiper.vue';
import PartCore from '@/components/solution/PartCore.vue';
import PartAdvantage from '@/components/solution/PartAdvantage.vue';
export default {
  data() {
    return {
      // p2
      p2Data: [
        {
          img: require('../../assets/img/solution/O2O/part2-icon1.png'),
          imgOver: require('../../assets/img/solution/O2O/part2-icon1-over.png'),
          span: '餐饮',
          p: '为O2O餐饮的信息整合交易平台、点餐服务、第三方配送服务、点餐服务、品牌线上运营等提供基于企业通信、大数据等的及时通讯及咨询服务，助力打通餐饮服务的线上线下系统，完善O2O餐饮的垂直服务和重度服务体系。',
        },
        {
          img: require('../../assets/img/solution/O2O/part2-icon2.png'),
          imgOver: require('../../assets/img/solution/O2O/part2-icon2-over.png'),
          span: '医疗',
          p: '为O2O医疗的线上咨询和线下对接、医患信息有效流动、线上营销和用户管理、医疗资源高度共享等提供安全信息传递、高效自动运转的信息化平台，助力打造更便捷、高效，更具人文关怀的智慧医疗。',
        },
        {
          img: require('../../assets/img/solution/O2O/part2-icon3.png'),
          imgOver: require('../../assets/img/solution/O2O/part2-icon3-over.png'),
          span: '出行',
          p: '以基于企业通信的产品服务和基于大数据的技术服务，为覆盖2.4亿用户规模、占移动互联网21%渗透率的出行O2O行业打造专业的企业通信解决方案，为资源、渠道、平台的无缝信息对接提供安全保障。',
        },
        {
          img: require('../../assets/img/solution/O2O/part2-icon4.png'),
          imgOver: require('../../assets/img/solution/O2O/part2-icon4-over.png'),
          span: '社区',
          p: '为家政服务、洗涤服务、家电维修服务等社区O2O行业的信息化平台建设和综合化工具打造提供完善的企业通信综合服务，提升O2O社区的服务体验，助力实现多维度的组合营销，打造智慧化社区。',
        },
      ],
      p2Datas: [],
      p2Title: '行业需求',
      // p3
      p3Data: [
        {
          img: require('../../assets/img/solution/O2O/part3-icon1.png'),
          img2: require('../../assets/img/solution/O2O/mobile/part3-icon1.png'),
          imgOver: require('../../assets/img/solution/O2O/part3-icon1-over.png'),
          span: '业务快速上云',
          p: 'O2O业务快速上云灵活更新，自由拓展',
        },
        {
          img: require('../../assets/img/solution/O2O/part3-icon2.png'),
          img2: require('../../assets/img/solution/O2O/mobile/part3-icon2.png'),
          imgOver: require('../../assets/img/solution/O2O/part3-icon2-over.png'),
          span: '实时短信通知',
          p: '实时的短信通知/验证保障O2O及时、高效的信息传递',
        },
        {
          img: require('../../assets/img/solution/O2O/part3-icon3.png'),
          img2: require('../../assets/img/solution/O2O/mobile/part3-icon3.png'),
          imgOver: require('../../assets/img/solution/O2O/part3-icon3-over.png'),
          span: '语音/短信验证码',
          p: '即时信息验证构建安全的O2O运营环境',
        },
        {
          img: require('../../assets/img/solution/O2O/part3-icon4.png'),
          img2: require('../../assets/img/solution/O2O/mobile/part3-icon4.png'),
          imgOver: require('../../assets/img/solution/O2O/part3-icon4-over.png'),
          span: '安全呼叫中心',
          p: 'O2O安全呼叫体系实现多方位的用户连接与信息安全',
        },
        {
          img: require('../../assets/img/solution/O2O/part3-icon5.png'),
          img2: require('../../assets/img/solution/O2O/mobile/part3-icon5.png'),
          imgOver: require('../../assets/img/solution/O2O/part3-icon5-over.png'),
          span: '虚拟手机卡',
          p: '更便捷的设备数据远程管理找回与删除等安全防护',
        },
        {
          img: require('../../assets/img/solution/O2O/part3-icon6.png'),
          img2: require('../../assets/img/solution/O2O/mobile/part3-icon6.png'),
          imgOver: require('../../assets/img/solution/O2O/part3-icon6-over.png'),
          span: '智能网络加速',
          p: '智能化加速，轻松应对O2O业务高并发促进良好的用户体验',
        },
      ],
      // p4
      p4Data: [
        {
          span: '降低运营成本',
          p: '基于企业通信式的产品组合服务，百悟为O2O行业客户提供具有性价比的集成通信服务，帮助客户降低信息、通信、网络和营销成本，更专注地创新O2O服务模式和产品技术。',
        },
        {
          span: '提升用户体验',
          p: '基于企业通信行业领先的自主平台优势，百悟为O2O行业客户提供个性化的、定制化的创新解决方案，打通用户线上行为和线下行为的有效连接，助力提升用户线上线下操作体验。',
        },
        {
          span: '完善系统保障',
          p: '基于多年的行业服务经验，百悟为O2O行业客户提供全方位的信息沟通渠道，为O2O商业闭环完善的系统加固防线保障，刺激用户的主动行为，为O2O社区运营提供便利化的信息服务。',
        },
        {
          span: '提升沟通效率',
          p: '基于运营商级的优质资源优势，百悟为O2O行业客户提供快速的沟通平台和通道，实现平台与用户之间的无缝信息对接，更高效的沟通效率以助推更快速的O2O行业发展。',
        },
      ],
      p4Datas: [],
      // p5
      p5Data: [
        {
          span: '短信',
          p: '百悟采取自主研发的分布式、高性能、高可用短信网关，为O2O行业客户提供简单、迅速、低成本的集成短信发送能力和私有化定制短信平台解决方案。',
        },
        {
          span: '语音',
          p: '百悟采取自主研发的分布式、高性能、高可用短信网关，为O2O行业客户提供简单、迅速、低成本的语音发送能力和私有化定制语音平台解决方案。',
        },
        {
          span: 'CDN',
          p: '通过百悟自建+融合的共享CDN平台，实现O2O行业客户集全局管理、分析、探测、操作、告警于一体的CDN加速服务，帮助客户提高访问速度、降低源站压力、解决访问瓶颈。',
        },
        {
          span: '新媒体<br>广告',
          p: '百悟以多维度定向技术和大数据优化算法，多平台辅助、多维度服务，为O2O行业客户提供一站式在线营销解决方案，实现个性化、精准化、经济化、全球化的大数据新媒体广告服务。',
        },
      ],
      // p6
      p6Data: [
        {
          img: require('../../assets/img/solution/O2O/part6-icon1.png'),
          imgOver: require('../../assets/img/solution/O2O/part6-icon1-over.png'),
        },
        {
          img: require('../../assets/img/solution/O2O/part6-icon2.png'),
          imgOver: require('../../assets/img/solution/O2O/part6-icon2-over.png'),
        },
        {
          img: require('../../assets/img/solution/O2O/part6-icon3.png'),
          imgOver: require('../../assets/img/solution/O2O/part6-icon3-over.png'),
        },
        {
          img: require('../../assets/img/solution/O2O/part6-icon4.png'),
          imgOver: require('../../assets/img/solution/O2O/part6-icon4-over.png'),
        },
        {
          img: require('../../assets/img/solution/O2O/part6-icon5.png'),
          imgOver: require('../../assets/img/solution/O2O/part6-icon5-over.png'),
        },
        {
          img: require('../../assets/img/solution/O2O/part6-icon6.png'),
          imgOver: require('../../assets/img/solution/O2O/part6-icon6-over.png'),
        },
      ],
    };
  },
  components: {
    PartFlexSwiper,
    PartCore,
    PartAdvantage,
  },
  mounted() {
    // 数据初始化
    this.p2Datas = [
      JSON.parse(JSON.stringify(this.p2Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p2Data.slice(-2))),
    ];
    this.p4Datas = [
      JSON.parse(JSON.stringify(this.p4Data.slice(0, 2))),
      JSON.parse(JSON.stringify(this.p4Data.slice(-2))),
    ];
    // 状态初始化
    this.start();
  },
  methods: {
    // 初始化
    start() {
      this.$refs.Part3Core.pCoreMove(0, this.p3Data[0].imgOver);
      this.$refs.Part3Core.pCoreClick(0, this.p3Data[0].img2, this.p3Data[0]);
      this.p5Click(0);
      this.p6Click(0, this.p6Data[0].imgOver);
    },
    // p5
    p5Click(i) {
      $('.p5_rLItem').removeClass('active');
      $('.p5_rRItem').removeClass('active');
      $('.p5_rLItem').eq(i).addClass('active');
      $('.p5_rRItem').eq(i).addClass('active');
    },
    // p6
    p6Click(i, img) {
      $('.p6_item').each((index, item) => {
        $(item).find('img').attr('src', this.p6Data[index].img);
      });
      $('.p6_item').removeClass('active');
      $('.p6_item').eq(i).find('img').attr('src', img);
    },
  },
};
</script>

<style lang="less" scoped>
/* 第一部分 */
.part1 {
  box-sizing: border-box;
  width: 100%;
  height: 723px;
  .part1_main {
    box-sizing: border-box;
    width: 1110px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > img {
      position: absolute;
      width: 826px;
      height: 805px;
      z-index: -1;
      top: 4px;
      right: -269px;
    }
  }
  .part1-title {
    width: 538px;
    span {
      font-family: FontExcellent;
      font-size: 40px;
      color: #333333;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;
    }
    p {
      font-size: 16px;
      color: #666666;
      text-align: justify;
      margin-bottom: 67px;
      line-height: 28px;
    }
    a {
      display: block;
      width: 184px;
      height: 53px;
      background-color: rgb(36, 110, 187);
      border-radius: 7px;
      text-align: center;
      line-height: 53px;
      font-size: 16px;
      color: #f9fbfd;
      letter-spacing: 0.5px;
    }
  }
}
.part5 {
  width: 100%;
  padding-top: 79px;
  padding-bottom: 127px;
  background: url('../../assets/img/solution/O2O/part5-bg.png') no-repeat;
  background-size: 100% 100%;
  .part-title {
    color: #fefefe;
    margin-bottom: 62px;
  }
  .p5_main {
    width: 1111px;
    margin: 0 auto;
    height: 646px;
    background: rgba(241, 241, 241, 0.92);
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .p5_right {
      box-sizing: border-box;
      width: 513px;
      height: 579px;
      border: 2px solid #e4e6e9;
      border-radius: 7px;
      background: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .p5_rLeft {
        box-sizing: border-box;
        width: 112px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 16px;
        border-right: 1px solid #e4e6e9;
        .p5_rLItem {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          span {
            font-size: 24px;
            font-family: FontExcellent;
            font-weight: 400;
            color: #333333;
          }
        }
        .p5_rLItem.active {
          span {
            color: #005cb9;
            position: relative;
          }
          span::after {
            content: '';
            display: block;
            width: 4px;
            height: 38px;
            background: #005cb9;
            position: absolute;
            top: -3px;
            left: -13px;
          }
        }
      }
      .p5_rRight {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .p5_rRItem {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          p {
            display: block;
            width: 346px;
            text-align: justify;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 27px;
          }
        }
        .p5_rRItem.active {
          p {
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
  }
}
.part6 {
  width: 100%;
  padding-top: 78px;
  padding-bottom: 119px;
  .part-title {
    margin-bottom: 86px;
  }
  .p6_main {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .p6_item {
      box-sizing: border-box;
      width: 344px;
      height: 162px;
      background: #ffffff;
      border: 2px solid #f1f1f1;
      margin-bottom: 44px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .p6_item.active {
      border: 2px solid #0b63bb;
    }
  }
}
@media screen and (max-width: 768px) {
  /* 第一部分 */
  .part1 {
    height: 4.28rem;
    .part1_main {
      width: 6.14rem;
      > img {
        content: url('../../assets/img/solution/O2O/mobile/part1-icon.png');
        width: 4.85rem;
        height: 4.11rem;
        top: 0.17rem;
        right: -0.63rem;
      }
    }
    .part1-title {
      width: auto;
      height: auto;
      span {
        font-size: 0.4rem;
        margin-bottom: 0px;
        margin-top: -0.62rem;
      }
      p,
      a {
        display: none;
      }
    }
  }
  .part5 {
    padding-top: 0.58rem;
    padding-bottom: 1.1rem;
    background: url('../../assets/img/solution/O2O/mobile/part5-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 0.69rem;
    }
    .p5_main {
      box-sizing: border-box;
      width: 6.2rem;
      height: 12.12rem;
      border-radius: 0.07rem;
      padding: 0.32rem 0px;
      flex-direction: column;
      > img {
        width: 5.78rem;
        margin-bottom: 0.18rem;
      }
      .p5_right {
        width: 5.78rem;
        height: 5.79rem;
        border: 0.02rem solid #e4e6e9;
        border-radius: 0.07rem;
        .p5_rLeft {
          width: 1.48rem;
          padding-bottom: 0.16rem;
          .p5_rLItem {
            flex: 1;
            span {
              font-size: 0.24rem;
            }
          }
          .p5_rLItem.active {
            span::after {
              width: 0.04rem;
              height: 0.38rem;
              top: -0.03rem;
              left: -0.13rem;
            }
          }
        }
        .p5_rRight {
          .p5_rRItem {
            p {
              width: 3.78rem;
              font-size: 0.16rem;
              line-height: 0.27rem;
            }
          }
        }
      }
    }
  }
  .part6 {
    width: 100%;
    padding-top: 0.56rem;
    padding-bottom: 0.81rem;
    .part-title {
      margin-bottom: 0.51rem;
    }
    .p6_main {
      width: 6.14rem;
      .p6_item {
        width: 2.91rem;
        height: 1.37rem;
        border: 0.02rem solid #f1f1f1;
        margin-bottom: 0.23rem;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .p6_item.active {
        border: 0.02rem solid #0b63bb;
      }
    }
  }
}
</style>
<style lang="less">
.O2O {
  .partFlexSwiper {
    background: url('../../assets/img/solution/transportation/part2-bg.png')
      no-repeat;
    background-size: 100% 100%;
    .part-title {
      margin-bottom: 78px;
    }
    .partFlexSwiper-item {
      width: 256px !important;
      height: 458px !important;
      padding-top: 40px !important;
      > div {
        height: 95px !important;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .O2O {
    .partAdvantage {
      background: url('../../assets/img/solution/O2O/mobile/part4-bg.png')
        no-repeat;
      background-size: 100% 100%;
    }
    .partFlexSwiper {
      background: url('../../assets/img/solution/transportation/mobile/part2-bg.png')
        no-repeat;
      background-size: 100% 100%;
      .part-title {
        margin-bottom: 0.78rem;
      }
      .partFlexSwiper-item {
        width: 2.56rem !important;
        height: 4.58rem !important;
        padding-top: 0.4rem !important;
        > div {
          height: 0.95rem !important;
        }
      }
    }
  }
}
</style>
